<template>
  <div class="container-page cases-list__container">
    <v-card class="mt-0 transparent elevation-0 cases-list__container__card">
      <v-container
        class="pa-0"
        fluid>
        <cases-list />
      </v-container>
    </v-card>

    <transition
      name="slide"
      mode="out-in"
      appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
  import CasesList from '@/components/Cases/List'
  export default {
    components: {
      CasesList
    }
  }
</script>

<style lang="sass">
  .cases-list
    &__container
      height: 100%

    &__card
      height: 100%
</style>
