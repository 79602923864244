<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-toolbar class="toolbar" color="#F7F8FC" flat>
        <h2 class="font-weight-bold display-1">
          Casos
        </h2>
        <v-spacer />
        <v-text-field
          v-model="search"
          class="search-input"
          label="Pesquise aqui"
          prepend-inner-icon="mdi-magnify"
          solo
          dense
          flat
          clearable
          hide-details />
        <v-btn class="ml-2" color="primary" :to="{ name: 'CaseNew' }" depressed>
          Adicionar caso
        </v-btn>
        <v-btn class="ml-2" color="primary" :to="{ name: 'MultiplesCasesNew' }" depressed>
          Importar lote
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-0 cases-container">
        <template>
          <v-tabs
            slider-color="primary"
            class="tabs-transparent mb-5">
            <v-tab
              ripple
              class="font-weight-bold"
              :to="{ name: 'Cases', params: { type: 'todos' } }">
              Todos
            </v-tab>
            <v-tab
              ripple
              class="font-weight-bold"
              :to="{ name: 'Cases', params: { type: 'em-andamento' } }">
              Em Andamento
            </v-tab>
            <v-tab
              ripple
              class="font-weight-bold"
              :to="{ name: 'Cases', params: { type: 'em-negociacao' } }">
              Em Negociação
            </v-tab>
            <v-tab
              ripple
              class="font-weight-bold"
              :to="{ name: 'Cases', params: { type: 'com-acordo' } }">
              Com Acordo
            </v-tab>
            <v-tab
              ripple
              class="font-weight-bold"
              :to="{ name: 'Cases', params: { type: 'finalizado' } }">
              Encerrado
            </v-tab>
            <v-tab
              ripple
              class="font-weight-bold"
              :to="{ name: 'MultiplesCases' }">
              Lote
            </v-tab>
          </v-tabs>

          <v-card
            flat
            class="transparent content-tab">
            <router-view />
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      search: ''
    }),
    computed: {
      ...mapGetters({
        isGuest: 'user/isGuest',
        isNegotiator: 'user/isNegotiator'
      })
    },
    watch: {
      search(value) {
        this.$root.$emit('caseSearch', value)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .content-tab
    height: calc(100vh - 160px) !important
</style>
