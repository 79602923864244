<template>
  <div class="list-cases">
    <perfect-scrollbar :options="options" @ps-y-reach-end="nextPage">
      <v-list class="py-0" dense>
        <template v-for="(item, index) in cases">
          <v-list-item :key="index" class="py-3 px-5" link :to="{ name: 'CaseShow', params: { caseId: item.id } }">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 font-weight-bold">
                {{ item.recipient.name }}
              </v-list-item-title>
              <v-list-item-title class="overline">
                {{ item.code }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 font-weight-bold">
                <v-chip small>
                  {{ $t('case.state.' + item.state) }}
                </v-chip>
              </v-list-item-title>
              <v-list-item-title v-if="item.state === 'negotiation'" class="overline">
                {{ item.administratorName }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small>
                0
              </v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-divider v-if="index + 1 < cases.length" :key="'divider-' + index" />
        </template>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import { mapGetters } from 'vuex'
  import Loading from '@/components/ui/Loading'
  import TicketsTable from '@/components/ui/TicketsTable'
  import { setTimeout } from 'timers'
  import EmptyState from '@/components/ui/EmptyState'
  import PromiseKey from '@/components/ui/PromiseKey'

  const rowHeight = 48
  const compensationArea = 350
  const pageSize = () => parseInt((window.innerHeight - compensationArea) / rowHeight)

  const STATES = {
    'todos': [],
    'em-andamento': ['initialProposal', 'negotiation', 'mediation', 'directNegotiation', 'draft'],
    'em-negociacao': ['negotiation'],
    'com-acordo': ['accepted', 'directAgreeement', 'externalAgreement'],
    'finalizado': ['refused', 'finished', 'directDisagreement', 'externalRefused', 'renegotiated', 'dropped']
  }

  export default {
    name: 'CaseList',
    components: { AppList, EmptyState, Row, Loading, TicketsTable, PromiseKey },
    data: () => ({
      search: '',
      options: { suppressScrollX: true, wheelPropagation: false },
      stateList: [
        { label: 'Todos', value: '' },
        { label: 'Aceito', value: 'accepted' },
        { label: 'Acordo Externo', value: 'externalAgreement' },
        { label: 'Recusa Externa', value: 'externalRefused' },
        { label: 'Finalizado', value: 'finished' },
        { label: 'Análise', value: 'draft' },
        { label: 'Baixado', value: 'dropped' },
        { label: 'Proposta inicial', value: 'initialProposal' },
        { label: 'Negociação', value: 'negotiation' },
        { label: 'Acordo Presencial', value: 'directAgreement' },
        { label: 'Recusa Presencial', value: 'directRefused' },
        { label: 'Aceite Presencial', value: 'directAccepted' },
        { label: 'Negociação', value: 'negotiation' },
        { label: 'Mediação', value: 'mediation' },
        { label: 'Recusado', value: 'refused' }
      ],
      headers: [
        { text: 'Código', value: 'code', sortable: true },
        { text: 'Convidado', value: 'recipient.name', sortable: false },
        { text: 'Status', value: 'state', sortable: true }
      ],
      pagination: {
        negotiator: sessionStorage.getItem('CasePaginationNegotiator') || '',
        state: sessionStorage.getItem('CasePaginationState') || '',
        search: sessionStorage.getItem('CasePaginationSearch') || '',
        sortBy: [sessionStorage.getItem('CasePaginationSortBy') || 'code'],
        sortDesc: [JSON.parse(sessionStorage.getItem('CasePaginationDescending') || true)],
        page: parseInt(sessionStorage.getItem('CasePaginationPage') || 1),
        rowsPerPage: pageSize(),
        rowsPerPageItems: [pageSize()]
      }
    }),
    computed: {
      ...mapGetters({
        isAdministrator: 'user/isAdministrator',
        cases: 'cases/list',
        loading: 'cases/listLoading',
        isRender: 'cases/listRender',
        totalCases: 'cases/length',
        proposal: 'proposal/record',
        negotiators: 'user/list',
      }),
      showActionButton () {
        return (this.$can('CaseCreate') || this.$canForCompany('caseCreate')) && this.cases.length > 0
      },
      hasCaseId () {
        return !!this.$route.params.caseId
      },
      isFilterNegotiation () {
        return this.pagination.state === 'negotiation'
      },
      setName () {
        return this.$store.state.user.user.name
      },
      discount () {
        return this.proposal.currentValue - this.proposal.installmentSelected.currentValue
      },
      isVideoconference () {
        return this.$route.name === 'CaseVideoconferencia'
      },
      negotiatorsOrEmpty () {
        return [ ...this.negotiators, { id: '', name: 'Todos'} ]
      }
    },
    watch: {
      '$route.params.type' (type) {
        this.paginate({
          negotiator: "",
          state: STATES[type],
          search: "",
          sortBy: [ "code" ],
          sortDesc: [ true ],
          page: 1,
          rowsPerPage: 12,
          rowsPerPageItems: [ 12 ]
        })
      },
      isFilterNegotiation () {
        if (this.isAdministrator && this.isFilterNegotiation && (!this.negotiators || !this.negotiators.length)) {
          this.$store.dispatch('user/getList', 'negotiator')
        }
      },
      'pagination.negotiator' () {
        this.$store.dispatch('cases/getList', this.pagination)
      }
    },
    mounted () {
      this.paginate({
        negotiator: "",
        state: STATES[this.$route.params.type],
        search: "",
        sortBy: [ "code" ],
        sortDesc: [ true ],
        page: 1,
        rowsPerPage: 12,
        rowsPerPageItems: [ 12 ]
      })

      this.$root.$on('caseSearch', search => {
        this.search = search
        this.paginate({
          negotiator: "",
          state: STATES[this.$route.params.type],
          search,
          sortBy: [ "code" ],
          sortDesc: [ true ],
          page: 1,
          rowsPerPage: 12,
          rowsPerPageItems: [ 12 ]
        })
      })
    },
    methods: {
      nextPage () {
        const pageArgs = this.pagination

        !this.search && this.paginate({
          ...pageArgs,
          page: pageArgs.page + 1
        })
      },
      _failure () {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      },
      paginate (paginate) {
        this.$store.dispatch('cases/getList', paginate)
        this.pagination = paginate
      },
      modalResend (item) {
        this.$refs.communicationModal.modalResend(item)
      }
    }
  }
</script>

<style lang="sass">
  .list-cases
    height: calc(100vh - 160px)
    position: relative
    width: 100%

  .height-auto
    height: auto!important
    overflow: initial!important
    width: 100%

  .v-align-center.icon
    vertical-align: bottom

  .select-state
    position: relative
    top: 12px

  .box-tip
    border-radius: $radiusDefault
    margin: 8px 16px
    min-height: 100px
    width: 100%

    table td
      color: $textColor

  .ps
    margin-top: 0px
    height: calc(100vh - 170px)

  @media screen and (max-width: $max-mobile)
    .list-cases
      height: auto
      min-height: calc(100vh - 160px)

</style>
