<template>
  <tr
    :class="{'table--selected elevation-3': caseId === props.item.id}"
    tabindex="0"
    @keydown.enter="selectCase(props.item)"
    @click="selectCase(props.item)">
    <td>{{ props.item.code }}</td>
    <td>{{ props.item.recipient.name }}</td>
    <td>{{ $t('case.state.' + props.item.state) }}</td>
  </tr>
</template>

<script>
  export default {
    props: {
      caseId: {
        type: String,
        default: null
      },
      props: Object
    },
    methods: {
      selectCase (item) {
        this.$emit('selectCase', item)
      }
    }
  }
</script>

<style lang="sass">
  .table--selected
    background: #eee

</style>
